import UrlHandlerApiService from "@/core/services/url-handler.service";
import Vue from "vue";

const PromoCoinsApiService = {
  URL: "",
  async init() {
    this.URL = await UrlHandlerApiService.getBaseUrl();
  },

  GENERAL_RULES_API: "api/promo/v1/coins/general-rules",
  CAMPAIGNS_API: "api/promo/v1/coins/campaigns",
  CAMPAIGN_API: id => `api/promo/v1/coins/campaigns/${id}`,

  getGeneralRules() {
    return Vue.axios.get(this.URL + this.GENERAL_RULES_API).catch(error => {
      // console.log(error);
      throw new Error(`[KT] PromoCoinsApiService ${error}`);
    });
  },
  updateGeneralRules(body) {
    return Vue.axios.patch(this.URL + this.GENERAL_RULES_API, body).catch(error => {
      // console.log(error);
      throw new Error(`[KT] PromoCoinsApiService ${error}`);
    });
  },
  getCampaigns(params) {
    const config = {
      params
    };
    return Vue.axios.get(this.URL + this.CAMPAIGNS_API, config).catch(error => {
      // console.log(error);
      throw new Error(`[KT] PromoCoinsApiService ${error}`);
    });
  },
  createCampaign(body) {
    return Vue.axios.post(this.URL + this.CAMPAIGNS_API, body).catch(error => {
      // console.log(error);
      throw new Error(`[KT] PromoCoinsApiService ${error}`);
    });
  },
  updateCampaign(body) {
    return Vue.axios.patch(this.URL + this.CAMPAIGN_API(body?.id), body).catch(error => {
      // console.log(error);
      throw new Error(`[KT] PromoCoinsApiService ${error}`);
    });
  },
  deleteCampaign(id) {
    return Vue.axios.delete(this.URL + this.CAMPAIGN_API(id)).catch(error => {
      // console.log(error);
      throw new Error(`[KT] PromoCoinsApiService ${error}`);
    });
  }
};

export default PromoCoinsApiService;
