<template>
  <v-dialog class="my-custom-dialog" v-model="dialog" persistent max-width="500px">
    <v-card class="mx-auto" max-width="500px" height="100%">
      <div class="d-flex flex-row-reverse">
        <i
          @click="close()"
          class="ki ki-close icon-xs text-muted btn btn-xs btn-icon btn-light btn-hover-primary"
        ></i>
      </div>
      <div class="offcanvas-header d-flex align-items-center">
        <h3 class="m-0 pb-10 dialog-title">
          {{ $t("coins.generalRules") }}
        </h3>
        <br />
        <br />
      </div>
      <div class="pt-2 flex justify-content-between">
        <p class="nc-title">1 {{ $t("coins.coinWillHaveValue") }}:</p>
      </div>
      <v-form ref="form" lazy-validation @submit.prevent="save()">
        <div class="d-flex flex-wrap">
          <v-text-field
            v-for="(item, index) in innerForm.exchangeRates"
            :class="['w-50', index % 2 ? 'pl-2' : 'pr-2']"
            v-bind:key="index"
            v-model="innerForm.exchangeRates[index].value"
            :label="$t('coins.enums.' + innerForm.exchangeRates[index].userType) + ' *'"
            :rules="rules.required"
            type="number"
            step="0.01"
          ></v-text-field>
        </div>
        <div class="pt-4 d-flex justify-content-between">
          <p class="nc-title">{{ $t("coins.exchangeConditions") }}</p>
        </div>
        <div class="exchange-conditions">
          <p class="custom-text">
            {{ $t("coins.whenUserHas") }}
            <span class="input-container">
              <v-text-field
                v-model="innerForm.maxCollectedCoins"
                class="input-num m-0"
                type="number"
              ></v-text-field>
            </span>
            {{ $t("coins.coins") }} {{ $t("coins.recieveVoucher") }}.
          </p>
        </div>

        <div class="pt-4 flex justify-content-between">
          <p class="nc-title">{{ $t("coins.explanationPopup") }}</p>
        </div>
        <v-textarea v-model="innerForm.explanationText" auto-grow class="explanation"></v-textarea>
        <div class="d-flex btn-gap justify-content-end">
          <v-btn @click="close()" class="ml-4">
            {{ $t("common.cancel") }}
          </v-btn>
          <v-btn color="primary" class="ml-4" type="submit">
            <!-- :disabled="isSaving"-->
            {{ $t("common.save") }}
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import { VALIDATORS } from "@/common/validators";
import PromoCoinsApiService from "@/core/services/api.service.promo.coins";

export default {
  name: "coins-rule-dialog",
  props: {
    form: {
      exchangeRates: [],
      explanationText: null,
      maxCollectedCoins: null
    }
  },
  data() {
    return {
      dialog: false,
      rules: {
        required: VALIDATORS.required
      }
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    toggle() {
      this.dialog = !this.dialog;
    },
    async save() {
      await PromoCoinsApiService.updateGeneralRules(this.innerForm).then(response => {
        this.close();
        this.$emit("save", {
          formData: this.innerForm,
          response
        });
        this.$bvToast.toast(this.$i18n.t("common.savedSuccessfully"), {
          title: this.$i18n.t("menu.coins"),
          solid: true,
          variant: "success",
          autoHideDelay: 1000
        });
      });
    }
  },
  computed: {
    innerForm() {
      return JSON.parse(JSON.stringify(this.form));
    }
  }
};
</script>
<style lang="scss" scoped>
.my-custom-dialog {
  align-self: flex-end;
  height: 100% !important;
  margin: 0 !important;
}

.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}
.v-dialog > .v-card {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

::v-deep .v-form .theme--light.v-input input {
  color: #1976d2;
  padding-left: 5px;
}

::v-deep .custom-text .v-text-field {
  padding-top: 0;
}
::v-deep .my-custom-dialog .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}

.custom-text {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.14px;
}

.dialog-title {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.24px;
}

.nc-title {
  color: #4d4d4d;
  font-size: 20px;
  font-weight: 600;
}
.gap {
  gap: 29px;
  .v-text-field .v-label {
    overflow: visible;
    text-overflow: initial;
  }
}

.exchange-conditions {
  white-space: nowrap;

  span {
    display: inline-block;
  }

  .input-container {
    display: inline-block; /* Keeps input inline */
    .input-num {
      width: 100px;
    }
  }

  .custom-text {
    white-space: normal;
  }
}
</style>
