import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import AddCampaign from "./offcanvas/add-campaign.vue";
import CoinsRuleDialog from "./dialog/coins-rule-dialog.vue";
import PromoCoinsApiService from "@/core/services/api.service.promo.coins";
import { EXCHANGE_RATE_LABELS } from "./coin.enums";
import { VALIDATORS } from "@/common/validators";
import { formatUTCToLocal } from "@/common/date-format";

export default {
  components: { AddCampaign, CoinsRuleDialog },
  data() {
    return {
      exchangeRageLabels: EXCHANGE_RATE_LABELS,
      isInitialized: false,
      generalRules: {},
      dataSource: [],
      dataSourceArchive: [],
      loading: false,
      loadingArchive: false,
      rules: {
        required: VALIDATORS.required
      },
      total: 0,
      totalArchive: 0,
      pagination: {},
      paginationArchive: {}
    };
  },
  watch: {
    pagination: {
      handler() {
        if (this.isInitialized) {
          this.getCampaigns();
        }
      },
      deep: true
    },
    paginationArchive: {
      handler() {
        if (this.isInitialized) {
          this.getArchive();
        }
      },
      deep: true
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    await PromoCoinsApiService.init();

    this.getGeneralRules();
    this.getCampaigns();
    this.getArchive();
    this.isInitialized = true;
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo", "currentUser"]),
    name() {
      return this.$i18n.t("menu.coins");
    },
    headers() {
      return [
        {
          text: this.$i18n.t("commonTable.name"),
          align: "start",
          id: "name",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$i18n.t("coins.promoStart"),
          align: "start",
          id: "startsAt",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$i18n.t("coins.promoFinish"),
          align: "start",
          id: "endsAt",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$i18n.t("commonTable.country"),
          align: "start",
          id: "country",
          sortable: false
        },
        {
          text: this.$i18n.t("commonTable.cities"),
          align: "start",
          id: "cities",
          sortable: false,
          width: "20%"
        },
        {
          text: this.$i18n.t("coins.enums.Regular"),
          align: "center",
          id: "regular",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$i18n.t("coins.enums.VipClubMember"),
          align: "center",
          id: "vip",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$i18n.t("coins.enums.Affiliate"),
          align: "center",
          id: "affiliate",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$i18n.t("coins.enums.LoyaltyClubMember"),
          align: "center",
          id: "loyalty",
          sortable: false,
          width: "10%"
        },
        {
          text: "",
          align: "start",
          id: "actions",
          sortable: false,
          width: "3%"
        }
      ];
    }
  },
  methods: {
    async getGeneralRules() {
      await PromoCoinsApiService.getGeneralRules().then(res => {
        this.generalRules = res.data;
      });
    },
    async getCampaigns() {
      const params = {
        pageSize: this.pagination.itemsPerPage,
        pageNumber: this.pagination.page - 1,
        isActive: true
      };

      this.loading = true;
      await PromoCoinsApiService.getCampaigns(params)
        .then(res => {
          this.dataSource = this.adjustDates(res.data?.campaigns?.results || []);
          this.total = res.data?.campaigns?.totalCount || 0;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getArchive() {
      const params = {
        pageSize: this.pagination.itemsPerPage,
        pageNumber: this.pagination.page - 1,
        isActive: false
      };

      this.loadingArchive = true;
      await PromoCoinsApiService.getCampaigns(params)
        .then(res => {
          this.dataSourceArchive = this.adjustDates(res.data?.campaigns?.results || []);
          this.totalArchive = res.data?.campaigns?.totalCount || 0;
        })
        .finally(() => {
          this.loadingArchive = false;
        });
    },
    adjustDates(data) {
      return data.map(item => {
        item.startsAt = formatUTCToLocal(item.startsAt);
        item.endsAt = formatUTCToLocal(item.endsAt);

        return item;
      });
    },
    getCityNames(cities) {
      return cities.map(c => c?.name).join(", ");
    },
    openGeneralRuleDialog() {
      this.$refs.ruleDialog.open();
    },
    onOpenFlyout(event) {
      this.$refs.addCampaignFlyout.open(event);
    },
    onRuleDialogSave() {
      this.getGeneralRules();
    },
    onFlyoutSave() {
      this.pageNumber = 0;
      this.getCampaigns();
      this.getArchive();
    },
    async onDeleteCampaign(item) {
      this.loading = true;
      await this.onDelete(item)
        .then(() => {
          this.dataSource = this.dataSource.filter(dItem => dItem.id !== item.id);
          this.total = this.total - 1;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async onDeleteArchive(item) {
      this.loadingArchive = true;
      await this.onDelete(item)
        .then(() => {
          this.dataSourceArchive = this.dataSourceArchive.filter(dItem => dItem.id !== item.id);
          this.totalArchive = this.totalArchive - 1;
        })
        .finally(() => {
          this.loadingArchive = false;
        });
    },
    getExchangeType(item, type) {
      return item.exchangeRates.find(eItem => eItem?.userType === type)?.value;
    },
    onDelete(item) {
      return PromoCoinsApiService.deleteCampaign(item?.id).then(() => {
        this.$bvToast.toast(this.$i18n.t("common.savedSuccessfully"), {
          title: this.$i18n.t("menu.coins"),
          solid: true,
          variant: "success",
          autoHideDelay: 1000
        });

        return true;
      });
    },
    formatNumber(num, decimal = 2) {
      if (!num && num !== 0) return null;
      return parseFloat(num).toFixed(decimal);
    }
  }
};
