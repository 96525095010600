import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import KTLayoutFlyout from "@/assets/js/layout/extended/quick-flyout.js";
import SchedulerApiService from "@/core/services/api.service.scheduler";
import { EXCHANGE_RATE_LABELS } from "../coin.enums";
import { VALIDATORS } from "@/common/validators";
import PromoCoinsApiService from "@/core/services/api.service.promo.coins";
import { formatLocalToUTC } from "@/common/date-format";
import CountryService from "@/core/services/country.service";

export default {
  props: {
    //program: Object,
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "add-campaign",
  data() {
    return {
      valid: false,
      isSaving: false,
      isEdit: false,
      requiredRule: VALIDATORS.required,
      labels: EXCHANGE_RATE_LABELS,
      form: null,
      selectAll: false,
      flyoutRef: null,
      countries: [],
      countriesLoading: false,
      cities: [],
      allCities: [],
      loading: false,
      activeCountry: null
    };
  },

  created() {
    this.activeCountry = CountryService.getActiveCountry();
    this.setForm();
  },
  async mounted() {
    await SchedulerApiService.init();
    await this.getCountries();
    await this.getCities();
  },
  methods: {
    initFlyout() {
      KTLayoutFlyout.init(this.$refs["add_campaign"]);
      const el = KTLayoutFlyout.getElement();
      this.flyoutRef = new KTOffcanvas(el);
    },
    setForm(data) {
      this.form = {
        id: data?.id || null,
        name: data?.name || null,
        startsAt: data?.startsAt || null,
        endsAt: data?.endsAt || null,
        country: data?.country || null,
        cities: this.filterCities(data?.cities) || [],
        exchangeRates: data?.exchangeRates || [
          {
            userType: EXCHANGE_RATE_LABELS.REGULAR,
            value: 0
          },
          {
            userType: EXCHANGE_RATE_LABELS.LOYALTY,
            value: 0
          },
          {
            userType: EXCHANGE_RATE_LABELS.VIP,
            value: 0
          },
          {
            userType: EXCHANGE_RATE_LABELS.AFFILIATE,
            value: 0
          }
        ]
      };
    },
    filterCities(cities = []) {
      const cityMap = [];

      cities.forEach(item => {
        this.cities.forEach(cItem => {
          if (item.id !== cItem.id) return;
          cityMap.push(cItem);
        });
      });

      return cityMap;
    },
    filterCountry(countryId) {
      return this.countries.find(c => c.id === countryId);
    },
    async open(data) {
      this.isEdit = !!data;

      await this.getCountries();

      if (this.isEdit) {
        this.setForm(data);
        this.form.country = this.filterCountry(data?.country?.id);
        await this.getCities();
        this.form.cities = this.filterCities(data?.cities);
        this.selectAll = this.form.cities.length === this.cities.length;
      } else {
        this.setForm();
        this.form.country = this.filterCountry(this.activeCountry);
        await this.getCities();
      }

      this.initFlyout();
      this.flyoutRef.show();
    },
    close() {
      this.setForm();
      if (this.$refs.promoStart) {
        this.$refs.promoStart.clearHandler();
      }

      if (this.$refs.promoFinish) {
        this.$refs.promoFinish.clearHandler();
      }

      this.flyoutRef.hide();
    },
    previousState() {
      this.$router.go(-1);
    },
    async validateAndSave() {
      if (this.$refs.form.validate()) {
        this.saveCampaign();
      }
    },
    async saveCampaign() {
      const postModel = { ...this.form };

      if (!this.isEdit) {
        delete postModel.id;
      }

      postModel.country = { id: postModel.country?.id, name: postModel.country?.name };
      postModel.cities = postModel.cities.map(item => {
        return { id: item.id, name: item.name };
      });

      postModel.startsAt = formatLocalToUTC(postModel.startsAt);
      postModel.endsAt = formatLocalToUTC(postModel.endsAt);

      const request = this.isEdit
        ? PromoCoinsApiService.updateCampaign(postModel)
        : PromoCoinsApiService.createCampaign(postModel);

      await request.then(response => {
        this.close();
        this.setForm();
        this.$emit("save", {
          formData: postModel,
          response
        });

        this.$bvToast.toast(this.$i18n.t("common.savedSuccessfully"), {
          title: this.$i18n.t("menu.coins"),
          solid: true,
          variant: "success",
          autoHideDelay: 1000
        });
      });
    },
    getCities() {
      if (!this.form.country?.id) return;
      this.loading = true;

      return SchedulerApiService.getCities({
        countryId: this.form.country.id
      })
        .then(res => {
          this.cities = res.data || [];
          this.allCities = [...this.cities];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCountries() {
      this.countriesLoading = true;
      return SchedulerApiService.getCountries()
        .then(res => {
          this.countries = res.data || [];
        })
        .finally(() => {
          this.countriesLoading = false;
        });
    },
    handleSelectAll(val) {
      this.form.cities = val ? this.cities : [];
    },
    onCountryChange() {
      this.getCities();
    }
  },
  computed: {}
};
