import { render, staticRenderFns } from "./coins-rule-dialog.vue?vue&type=template&id=7a0435ce&scoped=true"
import script from "./coins-rule-dialog.vue?vue&type=script&lang=js"
export * from "./coins-rule-dialog.vue?vue&type=script&lang=js"
import style0 from "./coins-rule-dialog.vue?vue&type=style&index=0&id=7a0435ce&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a0435ce",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCard,VDialog,VForm,VTextField,VTextarea})
